/**
 * Vuex Store
 */

import Vue from 'vue';
import Vuex from 'vuex';

import apiServices from '@/services/api';
import browserServices from '@/services/browser';

/* New modules */
import Auth from './modules/Auth';
import AuthFirebase from './modules/AuthFirebase';
import Init from './modules/Init';
import Form from './modules/Form';
import Course from './modules/Course';
import CourseList from './modules/CourseList';
import Calendar from './modules/Calendar';
import AreaList from './modules/AreaList';
import Summary from './modules/Summary';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    Auth,
    AuthFirebase,
    Init,
    Form,
    Course,
    CourseList,
    Calendar,
    AreaList,
    Summary,
    services: {
      namespaced: true,
      modules: {
        api: apiServices,
        browser: browserServices,
      },
    },
  },
});
