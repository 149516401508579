import apiService from '@/services/api/api/store';
import benefitService from '@/services/api/benefit';
import courseService from '@/services/api/course';
import examService from '@/services/api/exam';
import languageService from '@/services/api/language/store';
import router from '@/services/api/router';
import translationService from '@/services/api/translation/store';
import localService from '@/services/api/local';
import serverService from '@/services/api/server';
import achievementService from '@/services/api/achievement';
import bootcampService from '@/services/api/bootcamp';

export default {
  namespaced: true,
  modules: {
    api: apiService,
    benefit: benefitService,
    course: courseService,
    exam: examService,
    language: languageService,
    router,
    translation: translationService,
    local: localService,
    server: serverService,
    achievement: achievementService,
    bootcamp: bootcampService,
  },
};
