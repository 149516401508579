import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import Routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: Routes,
});
/* eslint-disable-next-line */
router.beforeEach(async (to, from, next) => {
  if (from.name === 'maintenance') return null;

  if(to.name === 'Auth') return next();

  try {
    const isAuthenticated = await Store.dispatch('Auth/isAuthenticated');
    const exam = Store.state.Auth.exam;

    if (from.query.redir && !from.query.redir.startsWith('/')) {
      from.query.redir = `/${from.query.redir}`;
    }

    if (isAuthenticated && !exam && from.path === '/legacy-login' && from.query.redir && to.path !== from.query.redir){
      if(from.query.redir === 'dashboard') return next()

      return next(from.query.redir)
    }

    // If not authenticated and the route require authentication, redirected to the login with the query parameters (if any)
    if (!isAuthenticated && to.meta.requiresAuth) {
      const path = to.path;
      const query = path !== '/logout' ? { redir: path } : {};
      return next({ name: 'Login', query });
    }

    if (to.meta.history) Store.dispatch('services/api/router/history/add', { from, to, next });

    // If the user is taking an exam, force redirect to the exam screen
    if (exam !== null && to.name !== 'examStarted') {
      return next({
        name: 'examStarted',
        params: {
          courseCode: exam.course_code,
          exam: exam.exam
        }
      });
    }

    const isLoginPath = to.path === '/login' || to.path === '/legacy-login';

    // If the user is authenticated and manually wants to go to /login, redirect to courses
    if(isAuthenticated && isLoginPath){
      return next({name: 'courses'})
    }

    if(isAuthenticated && to.query.redir && from.fullPath !== to.query.redir){
      return next(to.query.redir);
    }

    /* Cases
    - Unauthenticated user and towards a route that does not require authentication
    - Authenticated user, on a route wanting to go to another route different from login
     */
    return next();
  } catch (error) {
    console.error(error);
  }
});

export default router;
