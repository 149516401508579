import axios from 'axios';

const initialState = () => ({
  fetching: null,
  error: null,
  values: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    FETCH_UPDATED(state, data) {
      data.forEach(course => {
        let icons3 = 'https://static.educacionit.com/alumni/assets/course/' + course.curso_id + '.svg';
        state.values.push({
          name: course.nombre,
          course_code: course.curso_id,
          uniqid: course.url,
          credits: course.it_creditos,
          area_id: course.area_id,
          areas: course.areas_cursos.map(function(area) {
            return area.area_id;
          }),
          icon: icons3,
        })
      })
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
    FETCHING(state, value){
      state.fetching = !!value;
    }
  },
  actions: {
    async get({ commit }, course_code) {
      const params = {
        pais_id: 'AR'
      }

      return await axios
        .get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + `public/cursos/${course_code}`, {params})
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async fetch({ commit }, params = {}) {
      commit('RESET_STATE');
      commit('FETCHING', true);

      const defaultParams = {
        pais_id: 'AR',
        listar: 1,
        sort: 'nombre',
        ...params
      }

      return await axios
        .get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + "public/cursos", {params: defaultParams})
        .then((response) => {
          commit('FETCH_UPDATED', response.data.data);
          commit('FETCHING', false);

          return response;
        })
        .catch((error) => {
          commit('FETCHING', false);
          return error;
        });
    },
  },
  getters: {
    fetching: (state) => state.fetching,
    courses: (state) => state.values,
  },
};
