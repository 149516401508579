import axios from "axios";

const initialState = () => ({
  country: null,
  region: null,
});

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetch({ state }) {
      try{
        const response = await axios.get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + "init?include=pais,region", {});

        state.country = response.data.pais;
        state.region = response.data.region;
      } catch(e){
        console.error(e);
      }
    },
  },
  getters: {
    country: (state) => state.country,
    region: (state) => state.region,
  },
};
